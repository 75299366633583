export const getItemWaitingTime = function (list) {
    let res = 0;
    try {
        if (list.length == 0)
            return 0
        // let res = list.find(element => JSON.parse(element.service.toLowerCase().includes('wait')));
         res = list.find(element => element.service.toLowerCase().includes('wait')).time;
         console.log(res)
        if (res == undefined)
            return 0

    } catch (err) {
        console.log(err)
    }

    return res
}

export const getItemsAdditionalStops = function (list) {

    //let res = list.filter(element => JSON.parse(element.service.toLowerCase().includes('stop')))
    let res = list.filter(element => element.service.toLowerCase().includes('stop'))

    if (res == undefined)
        return 0

    return res.length
}